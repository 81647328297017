@import '../../utils/Variables.scss';
.dashboard-campaign-card {
  float: left;
  height: 546px;
  min-width: 465px;
  width: 49%;
  margin-top: 32px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(154, 161, 171, 0.3);
  border-radius: 8px;
  position: relative;
}
.dashboard-campaigns-total-publish-title {
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #979797;
  margin-top: 30px;
}
.dashboard-campaigns-total-publish-value {
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 45px;
  display: flex;
  align-items: center;
  text-align: center;
  color: $inkBlack;
  margin-top: 12px;
  margin-bottom: 42px;
  display: block;
}
.dashboard-campaign-row {
  margin: 0 30px;
}
.dashboard-campaign-info-title {
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #979797;
  margin-bottom: 12px;
}
.dashboard-campaign-info-value-row {
  margin-bottom: 30px;
}
.dashboard-campaign-info-value {
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: $inkBlack;
  display: inline-block;
  margin-right: 4px;
}
