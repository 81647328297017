@import '../../utils/Variables.scss';
.dashbard-coupon-card {
  height: 546px;
  min-width: 465px;
  margin-top: 32px;
  margin-right: 2%;
  float: left;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(154, 161, 171, 0.3);
  border-radius: 8px;
  width: 49%;
  position: relative;
}
.dashboard-coupon-chart-container {
  margin-top: 30px;
  position: relative;
}
.dashboard-coupon-chart-info-container {
  position: absolute;
  top: 95px;
  width: 100%;
}
.dashboard-coupon-chart-info-text {
  &-title {
    font-family: Muli;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: $commentColor;
  }
  &-value {
    display: block;
    font-family: Muli;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: $inkBlack;
    margin-top: 8px;
  }
}

.dashboard-coupon-info-container {
  margin-top: 40px;
  padding-left: 30px;
  padding-right: 38px;
}
.dashboard-coupon-info-row {
  display: flex;
  margin-bottom: 20px;
  position: relative;
}

.dashboard-coupon-info-color {
  width: 30px;
  height: 20px;
  border-radius: 4px;
  margin-right: 12px;
  align-self: center;
}
.dashboard-coupon-info-title {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  align-self: center;
  color: $inkBlack;
  margin-bottom: 0px;
}
.dahsboard-coupon-info-value {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  color: $inkBlack;
  position: absolute;
  right: 0;
  align-self: center;
  margin-bottom: 0px;
}
.coupons-overview-link {
  right: 38px;
  position: absolute;
  bottom: 30px;
}
