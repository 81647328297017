@import '../../utils/Variables.scss';

.language-tab-container {
  height: 60px;
  background-color: white;
  border-bottom: 1px solid #dadada;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0px 0px 10px 0px rgba(154, 161, 171, 0.3);
}

a.nav-item.nav-link {
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  line-height: 59px;
  height: 59px;
  font-size: 16px;
  color: #8c8c8c;
  text-align: center;
  margin-left: 30px;
  padding: 0px;
  &.active {
    border-bottom: solid 3px $navyBlue;
    color: $inkBlack;
  }
}

.nav-tabs .nav-link {
  border: none;
}

a.nav-item.nav-link:focus,
a.nav-item.nav-link:active {
  outline: none;
  box-shadow: none;
  color: #404040;
}
