@import '../../utils/Variables.scss';

.message-channel-area {
  justify-content: space-evenly;
}

.message-channel-container {
  // height: 334px;
  max-height: 350px;
  max-width: 300px;
  min-width: 260px;
  flex: 1;
  padding: 20px;
  height: 350px;
}

.message-channel-suggest {
  text-align: center;
  color: #8c8c8c;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.channel-title-div {
  display: flex;
  justify-content: center;
}
