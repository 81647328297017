.new-customer-chart-tool-tips {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(154, 161, 171, 0.3);
  border-radius: 4px;
  padding: 10px;
}
.new-customer-chart-dot {
  width: 7px;
  height: 7px;
  border-radius: 3.5px;
  display: inline-block;
  margin-right: 7px;
  margin-bottom: 1px;
}
.new-customer-chart-info {
  display: inline-block;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}
.new-customer-chart-tooltips-date {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
  color: #8c8c8c;
  margin-top: 5px;
}
