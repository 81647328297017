.onboarding-slide-two-vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  .onboarding-slide-two-title {
    font-family: Mulish;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 44px;
    color: #000000;
    text-align: center;
    align-self: center;
    margin-top: 45px;
    padding: 0px 20px;
  }
  .onboarding-slide-two-image {
    width: 57%;
    height: auto;
    align-self: center;
  }

  .onboarding-slide-two-text-container {
    margin-top: 24px;
    width: 92%;
    align-self: center;
    flex-direction: column;
    display: flex;
  }

  .onboarding-slide-two-text-title {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #002148;
    margin-bottom: 0px;
  }

  .onboarding-slide-two-text {
    font-family: Mulish;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0px;
  }
}
