@import '../../../utils/Variables.scss';
.use-coupon-prompt-container {
  padding: 40px;
  width: calc(100vw - 80px);
  max-width: 800px;
  border-radius: 10px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
}

.use-coupon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.use-coupon-prompt-header {
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 26px;
  line-height: 33px;
  color: #4d5560;
  margin-top: 40px;
  align-self: center;
  text-align: center;
}


.use-coupon-prompt-description {
  max-width: 387px;
  min-width: 387px;
  align-self: center;
  margin-top: 10px;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $inkBlack;
  text-align: center;
}

.use-coupon-prompt-buttons {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.use-coupon-prompt-button {
  margin-right: 0;
}

.use-coupon-prompt-first-button {
  align-self: center;
  background: $navyBlue;
  margin-right: 0px;
}

.use-coupon-prompt-link-button {
  margin-top: 20px;
  align-self: center;
  margin-right: 0px;
}