.add-sub-category-btn {
  margin-top: 20px;
  margin-bottom: 20px;
}
.delete-sub-category-btn {
  font-family: Mulish;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #4e8dd7;
  margin-left: 10px;
  cursor: pointer;
  align-self: center;
  text-transform: none;
  margin-bottom: 0;
  vertical-align: top;
  margin-top: 2px;
}
