.list-page-header-section {
  height: 168px;
  background-color: #ffffff;
  padding: 1px 20px 0 30px;
  border-bottom: solid 1px #dadada;
}
.page-title {
  align-self: center;
}
.list-page-title-section {
  display: flex;
  padding: 15px 20px 2px 0px;
}

.coupon-list-component-action-header {
  min-width: 1186px;
}

.list-head-setion-tab {
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 60px;
  width: 152px;
  text-align: center;
  border-bottom: solid 3px #4e8dd7;
}
.list-page-header-info {
  align-self: center;
}
.dropdown-action-list {
  display: flex;
  margin: 30px 40px;
}

.coupon-edit-btn {
  margin-left: 17px;
}
