.create-brand-section {
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(154, 161, 171, 0.3);
    border-radius: 8px;
    padding: 30px;
    margin-bottom: 150px;
}

.related-store-name {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #404040;
}