.extra-btn-container {
  display: flex;
}
.btn-popover {
  margin-left: 10px;
}
.extra-btn-container-has-primary {
  margin-right: 10px;
  display: inline;
}
.ant-popover-inner {
  border-radius: 10px;
}
.ant-popover-inner-content {
  padding: 0;
}
.popover-item {
  font-family: Mulish;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: #4e8dd7;
  padding: 10px 20px;
  border-top: 0.5px solid #dadada;
  cursor: pointer;
  display: flex;
  justify-content: center;
  &:first-child {
    border-top: 0;
  }
}
