.count-text {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #979797;
  margin-bottom: 0px;
  align-self: center;
  margin-left: auto;
  display: flex;
  align-items: center;
  padding-left: 20px;
  height: 40px;
}
