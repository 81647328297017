@import '../../utils/Variables.scss';
.dashboard-header {
  padding-bottom: 10px !important;
}
.dashboard-campaigns-coupons {
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 32px;
}
.dashboard-card-title {
  font-family: Muli;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-left: 30px;
  margin-top: 30px;
}
.dashboard-overview-link {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: #4e8dd7;
  float: right;
}
.dashboard-overview-link-icon {
  margin-left: 10px;
}
.dashboard-header-left {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}
.dashboard-title {
  display: inline-block;
  margin-right: 15px;
}
