.vendor-info {
    color: #4d5560;
    font-family: Mulish;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    margin-top: 30px;
    margin-bottom: 12px;
}

.vendor-header {
    margin-top: 0px;
}

.vendor-item-container {
    box-sizing: border-box;
    border: 1px dashed #C2C2C2;
    padding: 16px;
    position: relative;
    border-radius: 8px;
    width: 600px;
  }

.checkbox-container {
    padding-right: 40px;
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #4d5560;
}