.create-campaign-publish-container {
  width: 800px;
  height: auto;
  padding: 80px 40px;
}

.success-progress-image {
  width: 720px;
  height: 64px;
  object-fit: contain;
}

.campaign-publish-title {
  margin-top: 40px;
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 26px;
  line-height: 33px;
  text-align: center;
}

.campaign-publish-description {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #4d5560;
  margin-top: 4px;
  widows: 387px;
}

.campaign-publish-primary-button,
.campaign-publish-primary-button:hover {
  padding: 12px 24px;
  align-self: center;
}

.button-set-top-align {
  margin-top: 40px;
}

.button-set-top-align-back {
  align-self: center;
}

.maybe-later-publish {
  margin-top: 10px;
}

.btn:focus,
.btn.focus {
  box-shadow: none;
  text-decoration: none;
}
