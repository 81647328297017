@import '../../../utils/Variables.scss';

.campain-list-container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  display: flex;
}

.campaign-resume-button {
  margin-right: 16px;
}
