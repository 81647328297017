.custom-swiper-container {
  width: 100%;
  height: 100%;
  max-width: 1166px;
  .swiper-slide {
    height: 100%;
    min-height: 740px;
    max-width: 1166px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    padding-bottom: 40px
  }
}
