.option-container {
  display: list-item;
  list-style: none;
  width: 100%;
  margin-top: -24px;
}

.option {
  color: #333;
  font-size: 16px;
  text-align: center;
  min-height: 64px;
  min-width: 200px;
  background-color: #F5F5F5;
  border-radius: 8px;
  margin: 24px 24px 0 0;
  border: none;
}

.option-disabled {
  color: #999;
  cursor: not-allowed;
}

.option-selected {
  color: white;
  background-color: #4F8DD7;
}

.option-icon {
  width: 56px;
  height: 56px;
  border-radius: 8px;
  display: grid;
  margin: 12px auto 0;
}

.option-label {
  margin: 0;
  padding: 12px;
}
