@import '../../../utils/Variables.scss';
.general-info-container {
  padding: 90px 30px 30px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(154, 161, 171, 0.3);
  border-radius: 8px;
}
.first-section-title {
  font-family: MulishBlack;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: $darkBlue;
  margin: 0px;
}
.second-section {
  margin-top: 30px;
  .custom-switch-button {
    margin: 0px;
  }
  .error-field-message-style {
    margin-bottom: 0px;
  }
}
.second-title-section {
  margin-bottom: 8px;
}
.second-section-title {
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: $inkBlack;
}
.text-input-field {
  background: #ffffff;
  border: 1px solid $lightGreyTwo;
  box-sizing: border-box;
  border-radius: 4px;
  width: 82%;
  max-width: 700px;
  height: 40px;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  padding-left: 12px;

  &:active,
  &:focus {
    border: 1px solid #2b84ee;
    box-shadow: 0px 0px 8px rgba(43, 132, 238, 0.5);
    outline: 0px;
  }
}
.dropdown-section {
  width: 55%;
}
.show > .btn-primary.dropdown-toggle.dropdown-btn {
  background-color: #ffffff;
  border-color: $lightGreyTwo;
  color: $inkBlack;
}
.dropdown-btn.btn-primary {
  width: 100%;
  height: 40px;
  background-color: #ffffff;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $inkBlack;
  border: 1px solid $lightGreyTwo;
  display: flex;
  justify-content: space-between;

  &:hover,
  &:active,
  &:focus,
  &:not(:disabled):not(.disabled):active {
    background-color: #ffffff;
    border-color: $lightGreyTwo;
  }
  &:active,
  &:focus,
  &:not(:disabled):not(.disabled):active {
    border: 1px solid #2b84ee;
    box-shadow: 0px 0px 8px rgba(43, 132, 238, 0.5);
    outline: 0px;
  }
  &.dropdown-toggle::after {
    display: none;
  }
  img {
    margin-left: auto;
    align-self: center;
  }
}
.coupon-general-dropdown-menu {
  width: 100%;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $inkBlack;
  a:active {
    background-color: #2b84ee;
  }
}
.add-btn {
  margin-left: 5px;
  background-color: #ffffff;
  border: 0px;
  &:focus {
    outline: 0px;
  }
}
.multiple-selected-store-form {
  height: 202px;
  width: 45%;

  .store-list-item {
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    display: flex;
    align-items: center;
    color: #404042;
    height: 40px;
  }
}
.add-store-btn {
  align-self: flex-start;
}
.second-section-content {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: $inkBlack;
}

.prompt-title {
  font-family: Mulish;
  font-style: normal;
  font-weight: 800;
  font-size: 26px;
  line-height: 33px;
  letter-spacing: 0.5px;
  // text-transform: uppercase;
  color: $darkBlue;
  margin: 0px;
}

.add-store-button-left-margin {
  margin-left: 15px;
}

.custom-title-dropdown.field-has-error .filterable-toogle-area {
  border: 1px solid #ff0000;
}
