.store-qr-code-container,
.store-qr-code-container:hover {
  width: 120px;
  height: 120px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  position: relative;
  .preview-image {
    width: 120px;
    height: 120px;
    border: 1px solid #c2c2c2;
    border-radius: 4px;
  }
  .qr-code-download-container {
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(154, 161, 171, 0.5);
    background: #ffffff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .qr-code-download-image {
    width: 275px;
    height: 388px;
    margin-left: 8px;
    margin-top: 6px;
  }
  .qr-code-download-button {
    margin-top: 8px;
    width: 290px;
    height: 50px;
    border-radius: 0px 0px 8px 8px;
  }
}

.store-code-list-table > tbody > tr > td:nth-child(3) {
  position: unset;
}