.custom-editable-rank-icon {
  height: 12px;
  object-fit: contain;
  margin-top: 4px;
}

.custom-editable-rank {
  white-space: pre-wrap;
  padding-right: 5px;
}

.custom-editable-rank:hover {
  cursor: pointer;
}

.custom-editable-rank-icon-rtoate180 {
  transform: rotate(180deg);
}
