.create-customer-group-target {
  .custom-add-remove-mutiple-selector {
    display: flex;
    flex-wrap: wrap;
  }

  .custom-add-remove-mutiple-selector-buttons-area {
    margin-right: 10px;
    align-self: center;
  }
}
