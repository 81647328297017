.generate-coupon-modal {
    background: rgba(0, 0, 0, 0.4);

    .modal-content {
        width: 800px;
        padding: 20px 20px 40px 40px;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
        border-radius: 10px;
    }

    .modal-header {
        border: 0px;
        padding: 0px;

        .modal-close-btn {
            margin-left: auto;
            border: 0px;
            width: 32px;
            height: 32px;
            background-color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px;
            z-index: 1;

            &:focus,
            &:active {
                outline: 0px;
            }
        }
    }

    .modal-body {
        padding: 0px;
        margin-top: -16px;
        padding-right: 20px;

        .generate-coupon-title-section {
            .second-section-content {
                line-height: 24px;
                margin-top: 6px;
            }
        }

        .second-section-content {
            line-height: 36px;
        }
    }

    .modal-footer {
        padding: 0px;
        border: 0px;
        padding-right: 20px;

        .generate-coupon-btn {
            border-radius: 100px;
            height: 44px;
            margin-top: 30px;
        }
    }
}