@import '../../../utils/Variables.scss';

.create-step-four-container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  display: flex;
}

.list-item {
  display: list-item;
  margin-left: 40px;
}
