@import './ColorVariables.scss';
@import './FontVariables.scss';

$sideNavWidth: 240px;
$stepContinueAndBackNavHeight: 60px;
$listScrollableAreaWidth: calc(100vw - 240px);
$listFifteenPercentWidth: calc((100vw - 240px - 80px - 394px) * 0.15);
$listTwentyFivePercentWidth: calc((100vw - 240px - 80px - 394px) * 0.25);
$listFourItemPercentWidth: calc((100vw - 240px - 80px - 394px) * 0.1875);
$listNameMin: 182px;
$listItemMin: 122px;
$listCheckbox: 52px;
$listId: 122px;
$listStatus: 130px;
$listActions: 90px;

$hideSideMenuWidth: 1024px;