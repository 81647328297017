.onboarding-slide-container {
  justify-content: center;
  display: flex;
  .vertical-align {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-self: center;
  }
  .horizontal-align {
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-self: center;
  }

  .onboarding-slide-one-text-container {
    align-self: center;
    max-width: 402px;
    min-width: 279px;
    width: 28%;
    margin-right: 47px;
  }

  .onboarding-slide-one-text-container-pad {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .onboarding-slide-text {
    font-family: Mulish;
    font-style: normal;
    line-height: 24px;
    color: #002148;
  }
  .onboarding-slide-title {
    font-weight: 600;
    font-size: 18px;
  }
  .onboarding-slide-description {
    font-weight: 400;
    font-size: 14px;
  }
  .onboarding-slide-image {
    min-width: 482px;
    max-width: 624px;
    width: 53.5%;
    height: auto;
    align-self: center;
  }

  .center-text {
    text-align: center;
  }
}
