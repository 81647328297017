.dropdown-for-group-title {
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: #4d5560;
  margin-right: 17px;
  margin-bottom: 0px;
  align-self: center;
}
.filterable-toogle-area-special {
  border-radius: 4px;
}

.dropdown-for-group-container {
  width: 300px;
  height: 40px;
  background: #ffffff;
  margin-right: auto;
}

.dropdown-for-item-toggle {
  width: 40px;
  height: 40px;
  object-fit: contain;
  align-self: center;
}

.dropdown-for-item-title {
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 40px;
  text-transform: uppercase;
  color: #404040;
  margin-bottom: 0px;
}

.dropdown-for-item-divider {
  margin-top: 10px;
  margin-bottom: 10px;
}

.dropdown-for-item-menu {
  background: #ffffff;
  border: 1px solid #dadada;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(154, 161, 171, 0.5);
  border-radius: 8px;
  padding: 10px 20px;
  width: 200px;
  // position: fixed !important;
}

.error-field .filterable-toogle-area {
  border-color: #ff0000;
}