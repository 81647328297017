.receipt-container {
  box-sizing: border-box;
  border: 1px dashed #C2C2C2;
  margin-top: 20px;
  padding: 20px;
  position: relative;
}

.no-top-space {
  margin-top: 0;
}

.receipt-section-container {
  border-top: 1px dashed #C2C2C2;
  margin-top: 30px;
}

.d-block {
  display: block;
}
