@import '../../utils/Variables.scss';
.campaign-type-container {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 330px;
  background: white;
  border: 2px solid $lightGrey;
  box-sizing: border-box;
  border-radius: 10px;
  align-self: flex-start;
  min-width: 200px;
  max-width: 300px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 30px;
}

.campaign-type-container.no-image {
  height: 128px;
}

.no-image .campaign-type-image {
  margin-top: 0px;
}

.campaign-type-title {
  font-family: MuliExtraBold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: $inkBlack;
  align-self: center;
  margin-bottom: 0px;
  // margin-top: 25px;
}

.campaign-type-image {
  width: 103px;
  box-sizing: border-box;
  align-self: center;
  margin-top: 37px;
  object-fit: contain;
}

.campaign-type-description {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: $inkBlack;
  align-self: center;
  margin-top: 24px;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
}

.selected {
  border: 2px solid #2b84ee;
  box-sizing: border-box;
  box-shadow: 0px 0px 8px rgba(43, 132, 238, 0.5);
}

.campaign-type-container.list-description {
  max-width: 1000px;
  height: 200px;
}

.campaign-type-description-item {
  margin-left: 15px;
  display: list-item;
  font-size: 14px;
}