.campaign-category-detail .create-section-and-preview-container {
  margin-left: 40px;
  margin-right: 40px;
}
.campaign-category-detail-table {
  margin-top: 30px;
  th,
  td {
    border: 1px solid #dadada;
    padding: 20px;
  }
  th {
    width: 28%;
    font-family: Muli;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #404040;
  }
  td {
    white-space: pre;
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #404040;
  }
  th:nth-child(1),
  td:nth-child(1) {
    width: 16%;
    font-family: Muli;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    color: #404040;
  }
}
