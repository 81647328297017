.list-filter-list-search-section {
  // width: 350px;
  width: 285px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #c2c2c2;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0px 12px;
  display: flex;
}

.list-filter-search-field {
  margin-left: 8px;
  width: 100%;
  border: 0;
  &:focus {
    outline: 0px;
  }
}

.list-filter-search-icon {
  width: 21px;
}
