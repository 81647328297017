.cancel-button,
.cancel-button:focus,
.delete-button,
.delete-button:focus {
    font-family: Muli;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    height: 44px;
}

.cancel-button {
    padding: 11px 20px;
    margin-right: 16px;
}

.delete-button {
    padding: 11px 24px;
    &:focus {
        outline: 0px;
        padding: 11px 24px;
    }
}

.delete-single-coupon-info {
    display: block;
    margin-bottom: 18px;

    li {
        margin-bottom: 12px;
    }
}

.delete-modal-content-label {
    margin-bottom: 16px;
}