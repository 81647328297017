.coupon-overview-container {
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(154, 161, 171, 0.3);
    border-radius: 8px;
    padding: 1px 30px 30px 30px;
    margin: 30px 40px 100px;
}

.overview-section-title {
    margin-top: 30px;
}