.onboarding-slide-two-container {
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 10px 10px;
  height: 100%;

  .onboarding-slide-two-title {
    font-family: Mulish;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 44px;
    color: #000000;
    top: 41px;
    position: absolute;
    text-align: center;
    align-self: center;
    width: 100%;
    padding: 0px 140px;
  }

  .slide-two-image-background-1280 {
    width: 624px;
    height: 624px;
    left: 170px;
    top: 74px;
    position: absolute;
  }

  .slide-two-image-background-1000 {
    width: 432px;
    height: 432px;
    left: 159px;
    top: 170px;
    position: absolute;
  }

  .slide-two-dot-1-1280 {
    width: 7px;
    height: 7px;
    left: 318px;
    top: 450px;
    position: absolute;
  }

  .slide-two-dot-1-1000 {
    width: 7px;
    height: 7px;
    left: 272px;
    top: 447px;
    position: absolute;
  }

  .slide-two-underline-1-1280 {
    max-width: 318px;
    width: 27.3%;
    height: 43px;
    left: 35px;
    top: 450px;
    position: absolute;
  }

  .slide-two-underline-1-1000 {
    width: 214px;
    height: 43px;
    left: 62px;
    top: 443px;
    position: absolute;
  }

  .slide-two-dot-2-1280 {
    width: 7px;
    height: 7px;
    left: 625px;
    top: 316px;
    position: absolute;
  }

  .slide-two-dot-2-1000 {
    width: 7px;
    height: 7px;
    left: 444px;
    top: 339px;
    position: absolute;
  }

  .slide-two-underline-2-1280 {
    width: 38%;
    max-width: 445px;
    height: 42.5px;
    left: 628px;
    top: 317px;
    position: absolute;
  }

  .slide-two-underline-2-1000 {
    width: 345px;
    height: 34px;
    left: 447px;
    top: 341px;
    position: absolute;
  }

  .slide-two-dot-3-1280 {
    width: 7px;
    height: 7px;
    left: 597px;
    top: 570px;
    position: absolute;
  }

  .slide-two-dot-3-1000 {
    width: 7px;
    height: 7px;
    left: 428px;
    top: 517px;
    position: absolute;
  }

  .slide-two-underline-3-1280 {
    max-width: 500px;
    width: 41%;
    height: 85.5px;
    left: 600px;
    top: 568px;
    position: absolute;
  }

  .slide-two-underline-3-1000 {
    width: 379px;
    height: 112px;
    left: 429px;
    top: 519px;
    position: absolute;
  }

  .slide-two-text-section-title {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #002148;
    margin-bottom: 0px;
  }

  .slide-two-text-section-text {
    font-family: Mulish;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0px;
  }

  .slide-two-text-common {
    display: flex;
    flex-direction: column;
    z-index: 20;
  }

  .slide-two-text-1-1280 {
    max-width: 221px;
    width: 19%;
    left: 52px;
    top: 353px;
    position: absolute;
  }

  .slide-two-text-1-1000 {
    width: 160px;
    left: 74px;
    top: 352px;
    position: absolute;
  }

  .slide-two-text-2-1280 {
    max-width: 330px;
    width: 23.5%;
    left: 750px;
    top: 170px;
    position: absolute;
  }

  .slide-two-text-2-1000 {
    width: 244px;
    left: 552px;
    top: 190px;
    position: absolute;
  }

  .slide-two-text-3-1280 {
    max-width: 291px;
    width: 23.5%;
    left: 770px;
    top: 430px;
    position: absolute;
  }

  .slide-two-text-3-1000 {
    width: 234px;
    left: 591px;
    top: 400px;
    position: absolute;
  }
}
