.dashboard-date-range-btn {
  padding: 6px 20px;
  text-align: right;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #8c8c8c;
  cursor: pointer;
  margin-bottom: 10px;
}
.dashboard-date-range-btn.active {
  background: #e6f0fd;
  border-radius: 100px;
  color: #4e8dd7;
}
.dashboard-header-right {
  flex-wrap: wrap;
  justify-content: flex-end;
}
.date-picker-extra {
  margin-left: 10px !important;
  margin-bottom: 10px !important;
}
.dashboard-tab-range-selection {
  align-self: flex-end;
  margin-right: 40px;
}
