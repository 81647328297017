@import '../../utils/Variables.scss';

.side-menu-nav-area {
  padding-bottom: 20px;
}

.side-menu-nav-title {
  margin-left: 40px;
  height: 40px;
  font-family: MuliExtraBold;
  font-style: normal;
  font-size: 16px;
  line-height: 40px;
  margin-bottom: 0px;
  text-transform: uppercase;
  color: white;
  &.side-menu-nav-title-link {
    display: block;
    margin: 0;
    text-align: left;
    padding-left: 40px;
  }
}

.side-menu-nav-section {
  background-color: transparent;
  border: none;
}

.side-menu-nav-header {
  position: relative;
  min-height: 40px;
  width: 100%;
  margin: 0px 0px;
  padding: 0px 0px;
  background-color: transparent;
  border: none;
}

.side-menu-nav-header-focus {
  background-color: $sideNavHoverColor;
  text-decoration: none;
  color: white;
}

.side-menu-nav-header a:hover {
  background-color: $sideNavHoverColor;
  text-decoration: none;
  color: white;
}

.side-menu-nav-item {
  height: 40px;
  line-height: 40px;
  text-align: left;
  display: block;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: white;
  flex: none;
  order: 1;
  margin: 0px 0px;
  width: 100%;
}

.side-menu-nav-item-second {
  padding-left: 55px;
}

.side-menu-nav-item-second.sub {
  opacity: 0.8;
}

.side-menu-nav-item-third {
  padding-left: 70px;
}

.side-menu-nav-item-line-break {
  height: 60px;
  line-height: 30px;
}

span.arrow {
  position: absolute;
  right: 25px;
  display: inline-block;
  padding: 6px;
  border: 0.2px solid #FFFFFF;
  border-top: 0;
  border-left: 0;
}

span.show.arrow {
  top: 12px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
span.hidden.arrow {
  top: 22px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
.side-menu-nav-item-container:not(.show) {
  display: none;
}