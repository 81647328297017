@import '../../utils/Variables.scss';
.list-new-line {
  white-space: pre-wrap;
}

.rank-area-extra-width {
  width: 130px;
}

.rank-extra {
  margin-top: -40px;
}

$withStatusWidth: calc((100vw - 240px - 80px - 52px - 130px - 90px - 130px));
$listFifteenPercentWidth: calc((100vw - 240px - 80px - 394px) * 0.15);

.common-name-area-with-status {
  min-width: 182px;
  width: calc((100vw - 240px - 80px - 52px - 130px - 90px - 130px) * 0.25);
}
.common-name-area-with-status-count-1 {
  min-width: 122px;
  width: calc(
    (100vw - 240px - 80px - 52px - 130px - 90px - 130px) * (0.75 / 1)
  );
}
.common-name-area-with-status-count-2 {
  min-width: 122px;
  width: calc(
    (100vw - 240px - 80px - 52px - 130px - 90px - 130px) * (0.75 / 2)
  );
}
.common-name-area-with-status-count-3 {
  min-width: 122px;
  width: calc(
    (100vw - 240px - 80px - 52px - 130px - 90px - 130px) * (0.75 / 3)
  );
}
.common-name-area-with-status-count-4 {
  min-width: 122px;
  width: calc(
    (100vw - 240px - 80px - 52px - 130px - 90px - 130px) * (0.75 / 4)
  );
}
.common-name-area-with-status-count-5 {
  min-width: 122px;
  width: calc(
    (100vw - 240px - 80px - 52px - 130px - 90px - 130px) * (0.75 / 5)
  );
  //   width: $listFifteenPercentWidth;
}
.common-name-area-with-status-count-6 {
  min-width: 122px;
  width: calc(
    (100vw - 240px - 80px - 52px - 130px - 90px - 130px) * (0.75 / 6)
  );
  //   width: $listFifteenPercentWidth;
}

.common-name-area-without-status {
  min-width: 122px;
  width: calc((100vw - 240px - 80px - 52px - 130px - 90px) * 0.25);
}

.common-name-area-without-status-count-2 {
  min-width: 122px;
}

.common-field-area-without-status-count-1 {
  min-width: 122px;
  width: calc((100vw - 240px - 80px - 52px - 130px - 90px) * (0.75 / 1));
}
.common-field-area-without-status-count-2 {
  min-width: 122px;
  width: calc((100vw - 240px - 80px - 52px - 130px - 90px) * (0.75 / 2));
}
.common-field-area-without-status-count-3 {
  min-width: 122px;
  width: calc((100vw - 240px - 80px - 52px - 130px - 90px) * (0.75 / 3));
}
.common-field-area-without-status-count-4 {
  min-width: 122px;
  width: calc((100vw - 240px - 80px - 52px - 130px - 90px) * (0.75 / 4));
}
.common-field-area-without-status-count-5 {
  min-width: 122px;
  width: calc((100vw - 240px - 80px - 52px - 130px - 90px) * (0.75 / 5));
}
.common-field-area-without-status-count-6 {
  min-width: 122px;
  width: calc((100vw - 240px - 80px - 52px - 130px - 90px) * (0.75 / 6));
}

.common-status-area,
td.common-status-area {
  width: 130px;
  text-align: left;
  padding-left: 16px;
  padding-right: 16px;
  align-self: center;
}
.common-status-order {
  width: 233px;
}

.common-action-area {
  width: 90px;
}

.earnning-rule-list-scrollable-area-other {
  min-width: $listItemMin;
  width: calc((100vw - 240px - 52px - 122px - 153px) * (0.75 / 4));
}

.common-table-overflow {
  // overflow-y: scroll;
  min-height: 300px;
}

.sortable-helper td {
  display: flex;
  align-items: center;
}

.sortable-helper .all-list-scrollable-area-actions {
  height: 100%;
  .dropleft {
    margin: 0 auto;
  }
}

.campaign-list-section {
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0px 5px 10px 0px rgba(154, 161, 171, 0.3);
  background: white;
  margin-bottom: 30px;
  flex-direction: column;
  display: flex;
  // min-width: 1186px;
  width: 100%;
}

.campaign-list-scrollable-area-other {
  min-width: $listItemMin;
  width: $listFifteenPercentWidth;
}

.campaign-list-tab-container {
  height: 60px;
  background-color: white;
  border-bottom: 1px solid #dadada;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0px 0px 10px 0px rgba(154, 161, 171, 0.3);
  // min-width: 1186px;
  width: 100%;
  &.hide-tab-bar a {
    visibility: hidden;
  }
}

.campaign-list-tab-container a.nav-item.nav-link {
  // margin: 0px 20px;
  margin: 0px 15px;
}

.base-display-item-photo {
  object-fit: cover;
}

.base-rank-container {
  display: flex;
  flex-direction: row;
}
