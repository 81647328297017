
.transaction-action-prompt {
    .deactive-item-value {
        position: absolute;
        left: 248px;
        margin-left: 0;
    }
}

.transaction-import-btn {
    margin-right: 10px;
}