.clear-all-filter-container, .filter-item-container, .filter-item, .title-container {
  position: relative;
  min-height: 38px;
}
.filter-item {
  position: relative;
  display: inline-block;
  width: 240px;
  margin-right: 22px;
}
.btn.clear-filter {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  color: #4E8DD7;
  flex: none;
  background: none;
  border: none;
  position: absolute;
  right: 0;
}
.filter-item-container .btn.clear-filter {
  top: 24px;
}
.filter-item .filter-title {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  color: #4D5560;
  margin-top: 5px;
}
.filter-item-container .filterable-dropdown-area {
  max-width: 240px;
}
.filter-item-container .custom-title-dropdown {
  margin-right: 0;
}
.store-list-item-container {
  display: list-item;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 200%;
  color: #404040;
  margin-left: 22px;
}
.list-item-delete {
  width: 20px;
  margin-left: 10px;
}
.line {
  border: 1px solid #C2C2C2;
  border-radius: 4px;
  margin-bottom: 30px;
}
.dropdown-item .checkbox-container {
  margin-bottom: 0;
}