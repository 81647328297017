.deactive-item-value {
    margin-left: 20px;
    font-weight: 800;
}
.deactive-single-coupon-set-name {
    margin-left: 41px;
}
.deactive-coupon-set-name {
    margin-left: 126px;
}
.deactive-generated-coupon-number {
    margin-left: 24px;
}