.store-create-step-one {
  .dropdown-input-long {
    width: 355px;
  }
  .dropdown-short {
    width: 180px;
  }
  .qrcode-tips {
    display: block;
  }
}