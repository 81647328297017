@import '../../../utils/Variables.scss';

.step-one-title {
  font-family: MuliExtraBold;
  font-size: 16px;
  line-height: 20px;
  color: $inkBlack;
  margin-bottom: 0px;
}

.step-type-area {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;
}

.step-link-coupon-area {
  margin-top: 30px;
}

.step-link-coupon-area-title {
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: $lightBlack;
  margin-bottom: 0px;
}

.step-link-coupon-area-select {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  justify-content: flex-start;
  div {
    width: 100%;
  }
}

.add-new-link-icon {
  height: 34px;
  margin-left: 16px;
  align-self: center;
}

.participant-row {
  margin: 18px;
  margin-bottom: 0;
}