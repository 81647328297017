.loading-icon {
	width: 40px;
	height: 40px;
	object-fit: contain;
}

.loading-area {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #ffffff;
}