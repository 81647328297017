.feature-campaign-detail-image {
  width: 200px;
  height: auto;
  object-fit: contain;
}

.banners-detail-text {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: #404040;
  margin-bottom: 0px;
  word-break: break-word;
}

.carpark-privilege-image {
  width: 40px;
  height: 40px;
  object-fit: contain;
  margin-right: 10px;
}

.carpark-payment-image {
  width: 40px;
  height: 25px;
  object-fit: contain;
}

.mall-facilitie-image {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.store-category-text {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 40px;
  align-items: center;
  color: #404040;
  margin-bottom: 0px;
}
