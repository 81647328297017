.add-remove-botton {
  width: 27px;
  height: 27px;
}

.add-remove-botton:hover {
  cursor: pointer;
}

.add-remove-botton-space {
  margin-top: 9px;
}

.add-remove-botton-style {
  padding-left: 15px !important;
  padding-right: 15px !important;
  margin-top: 22px;
  align-self: flex-end;
}
.custom-add-remove-mutiple-selector-add-btn-container {
  max-width: 330px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
