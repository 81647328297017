@font-face {
  font-family: 'Muli';
  src: url('./assets/fonts/Muli.ttf') format('truetype');
}
@font-face {
  font-family: 'MuliBlack';
  src: url('./assets/fonts/Muli-Black.ttf') format('truetype');
}
@font-face {
  font-family: 'MuliBold';
  src: url('./assets/fonts/Muli-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'MuliExtraBold';
  src: url('./assets/fonts/Muli-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Mulish';
  src: url('./assets/fonts/Muli.ttf') format('truetype');
}
@font-face {
  font-family: 'MulishBlack';
  src: url('./assets/fonts/Muli-Black.ttf') format('truetype');
}
@font-face {
  font-family: 'MulishBold';
  src: url('./assets/fonts/Muli-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'MulishExtraBold';
  src: url('./assets/fonts/Muli-ExtraBold.ttf') format('truetype');
}

@import './utils/Variables.scss';
@import '~bootstrap/scss/bootstrap';
@import './utils/BtnCommonTheme.scss';
@import './utils/CommonTheme.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $backgroundGrey !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
