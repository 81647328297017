@import '../../../utils/Variables.scss';

.create-step-two-container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  display: flex;
}

.participant-title {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #4d5560;
  margin-top: 10px;
  margin-bottom: 0px;
  margin-left: 9px;
}

.reward-item-container {
  box-sizing: border-box;
  border: 2px dashed #999999;
  margin: 20px 0;
  padding: 20px;
  position: relative;
}

.custom-add-btn {
  width: 40px;
  height: 40px;
  background-color: transparent !important;
  border-color: transparent !important;
  padding: 0px;
  outline: none !important;
  border: 0px;
}

.custom-delete-btn {
  width: 20px;
  height: 20px;
  background-color: transparent !important;
  border-color: transparent !important;
  padding: 0px;
  outline: none !important;
  border: 0px;
}

.custom-delete-btn-container {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.contentStyle {
  width: 1800px;
}