.create-banner {
  display: flex;
  flex-direction: column;

  .display-order-banner {
    padding-left: 10px;
  }
  .base-temp-save-area {
    align-self: center;
    margin-left: auto;
  }
  .type-margin-top {
    margin-top: 0px;
  }
}
