.delete-campaign-pop-content {
  width: 673px;
  max-height: 200px;
  margin-top: 16px;
  padding-right: 87px;
  overflow-y: auto;
  margin-bottom: 0px;
  overflow-x: hidden;
  word-wrap: break-word;
}
.delete-campaign-pop-buttons {
  margin-top: 30px;
  display: flex;
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
}

.delete-campaign-pop-content-text {
  list-style: circle inside url('../../../assets/images/listing-style-dot.svg');
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #404040;
}

.delete-message-sections {
  font-weight: 800;
}
