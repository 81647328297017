.scanner-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1050;
}

.scanner-mask-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.scanner-container {
  display: flex;
  flex-direction: row;
}

.scanner-box {
  width: 240px;
  height: 240px;
  border: 2px solid #6659B0;
}

.scanner-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scanner-title {
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: white;
}

.scanner-subtitle {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: white;
}

.scanner-horizontal-mask {
  width: calc((100vw - 240px) / 2);
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.scanner-vertical-mask {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}