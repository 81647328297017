.cut-line {
  min-width: 600px;
  height: 1px;
  background: #CCCCCC;
  margin-top: 20px,
}

.coupon-content {
  margin: 20px 0,
}

.coupon-used {
  color: #999999;
  font-weight: 700;
  margin-left: 20px;
  height: 40px;
  width: fit-content;
  padding: 6px 25px;
}