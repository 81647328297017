.overview-card-container {
  width: 258px;
  height: 164px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(154, 161, 171, 0.3);
  border-radius: 8px;
  margin-right: 30px;
  margin-bottom: 30px;
  padding: 30px 30px 0 30px;
}
.overview-card-title {
  margin-bottom: 12px;
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  min-height: 40px;
  color: #979797;
}
.overview-card-value {
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #404040;
  margin-bottom: 4px;
}
