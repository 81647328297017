@import '../../utils/Variables.scss';
.radio-button {
  width: 19px;
  height: 19px;
  object-fit: contain;
}

.radio-button-label {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: $lightBlack;
  margin-bottom: 0px;
  margin-left: 9px;
  // text-align: center;
}

.radio-button-label:hover {
  cursor: pointer;
}

.radio-button-container {
  display: flex;
  flex-direction: row;
  margin-right: 41px;
  margin-bottom: 5px;
}
.radio-button-container:hover {
  cursor: pointer;
}
