@import '../../../utils/Variables.scss';

.coupon-list-scrollable-area-other {
  min-width: $listItemMin;
  width: $listFourItemPercentWidth;
}

.single-coupon-action-header {
  min-width: calc(1186px - 122px);
}

.single-coupon-list-pagination {
  min-width: calc(1186px - 122px);
}

.single-coupon-set-list-section {
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0px 5px 10px 0px rgba(154, 161, 171, 0.3);
  background: white;
  flex-direction: column;
  display: flex;
  min-width: calc(1186px - 122px);
  width: 100%;
}

.single-coupon-list-container {
  padding-top: 60px;
  min-width: calc(1186px - 122px);
  background-color: white;
  border-bottom: 1px solid #dadada;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0px 0px 10px 0px rgba(154, 161, 171, 0.3);
}
