.clear-all-filter-container, .filter-item-container, .filter-item, .title-container {
  position: relative;
  min-height: 38px;
}
.filter-item {
  position: relative;
  display: inline-block;
  width: 240px;
  margin-right: 22px;
}
.btn.clear-filter {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  color: #4E8DD7;
  flex: none;
  background: none;
  border: none;
  position: absolute;
  right: 0;
}
.filter-item-container .btn.clear-filter {
  top: 0;
}
.filter-item .filter-title {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  color: #4D5560;
  margin-top: 5px;
}
.filter-item-container .filterable-dropdown-area {
  max-width: 240px;
}
.filter-item-container .custom-title-dropdown {
  margin-right: 0;
}
.store-list-item-container {
  display: list-item;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 200%;
  color: #404040;
  margin-left: 0;
  list-style: inside;
}
.list-item-delete {
  width: 20px;
  margin-left: 10px;
}
.line {
  border: 1px solid #C2C2C2;
  border-radius: 4px;
  margin-bottom: 30px;
}
.dropdown-item .checkbox-container {
  margin-bottom: 0;
}
.list-container {
  // max-height: 384px;
  // overflow-y: scroll;
  padding: 20px;
  border: 1px solid #C2C2C2;
  border-top: none;
  border-radius: 0 0 4px 4px;
}
.dashed-border {
  border: 1px dashed #C2C2C2;
  border-top: none;
}
.none-border {
  border: none!important;
}
.title {
  margin: 0 0 0 20px;
  text-align: center;
  line-height: 46px;
}
.image-button {
  margin-bottom: 0;
}
.image-button-margin-left {
  margin-bottom: 0;
  margin-left: 0;
}
.custom-button {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  color: #4E8DD7;
  flex: none;
  background: none;
  border: none;
  margin: 0 20px 0 auto;
}
.title-containerV2 {
  background-color: #F8F8F8;
  border: 1px solid #C2C2C2;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  display: flex;
  margin-top: 28px;
}
.indicidual-content {
  max-height: 400px;
  overflow-y: scroll;
}

.ant-tree-title {
  font-family: Mulish;
  font-style: normal;
  font-size: 16px;
  color: #404040;
  white-space: nowrap;
}

.ant-tree .ant-tree-treenode {
  align-items: center!important;
}

.ant-tree-switcher {
  align-self: center!important;
}

.rtoate180 {
  transform: rotate(180deg);
}