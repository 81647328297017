.promotion-item-container {
  margin-bottom: 8px;
}
.promotion-item-container input {
  width: 100%;
}
.permotion-delete-btn-container {
  margin-left: 18px;
  display: inline-block; 
}
.promotion-delete-btn {
  background-color: transparent !important;
  border-color: transparent !important;
  padding: 0px;
  outline: none !important;
  border: 0px;
}
.create-section-tips {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #979797;
}