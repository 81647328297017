.order-edit-btn {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #2b84ee;
  flex: none;
  order: 0;
  align-self: center;
  text-decoration: none;
  cursor: pointer;
  display: none;
}
.all-list-scrollable-area-table-item-common:hover .order-edit-btn {
  display: inline-block;
}
.order-input {
  background: #ffffff;
  border: 1px solid #c2c2c2;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #404040;
  display: inline-block;
  width: 100px;
  height: 40px;
  margin-right: 11px;
}
.order-input:focus {
  outline: none;
}
.order-edit-done-btn {
  display: inline-block;
  vertical-align: middle;
  width: 76px;
  height: 30px;
  background: #ffffff;
  border: 2px solid #4e8dd7;
  border-radius: 100px;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #4e8dd7;
  text-align: center;
  padding-top: 5px;
  cursor: pointer;
}
