.flex-div {
  display: 'flex';
}

.custom-number-input-short {
  width: 100px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #c2c2c2;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px;
}

.custom-number-input-short:disabled {
  background: #f7f7f7;
}

.custom-number-input-long {
  width: 200px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #c2c2c2;
  box-sizing: border-box;
  border-radius: 4px;
}

.custom-input-short {
  width: 100px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #c2c2c2;
  box-sizing: border-box;
  border-radius: 4px;
}

.custom-input-upper {
  // text-transform: uppercase;
}

.help-message {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;

  color: #404042;
}

.margin-left-10-percent {
  margin-left: 10px;
}

.margin-top-8-percent {
  margin-top: 8px;
}

.margin-right-10-percent {
  margin-right: 10px;
}

.earning-rule-add-new-link-button {
  width: 40px;
  height: 40px;

  background-color: transparent !important;
  border-color: transparent !important;
  padding: 0px;
  outline: none !important;
  border: 0px;
}
// .earning-rule-add-new-link-button:hover,
// .earning-rule-add-new-link-button:active,
// .earning-rule-add-new-link-button:focus {
//   outline: none;
//   background-color: transparent;
//   border-color: transparent;
// }

.earning-rule-add-new-link-icon {
  background-image: '../../assets/images/add2.png';
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.tips-message {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 7px;
  /* identical to box height, or 114% */

  color: #979797;
  &.error {
    color: #ff0000;
  }
}

.custom-textarea-input-font {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Aillia_1.0/black/1 */

  color: #404040;
}

.custom-textarea-input-box {
  width: 700px;
  height: 112px;
  background: #ffffff;
  border: 1px solid #c2c2c2;
  box-sizing: border-box;
  border-radius: 4px;
}

.upload-file-button {
  width: 127px;
  margin-left: 0px;
}

.date-picker {
  max-width: 500px;
  width: 100%;
}

.custom-image-button,
.custom-image-button:hover,
.custom-image-button:focus {
  display: flex;
  padding: 0;
  background-color: transparent;
  border-color: transparent;
  outline: none;
}

.custom-file-box {
  border-width: 1px;
  border-style: solid;
  border-color: #ccc;
  line-height: 2.5px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-top: 13px;
  text-align: center;
}

.earning-rule-quantity-side-by-side {
  display: flex;
}

.white-space {
  width: 20px;
}
