@import '../../../utils/Variables.scss';

.next-step-only-one-step {
  width: 88px;
  height: 88px;
  align-self: center;
}

.next-step-content {
  display: flex;
  padding-bottom: 30px;
  justify-content: center;

  .next-step-one-item {
    position: relative;
  }

  .next-step-tail-line {
    position: absolute;
    left: 70px;
    margin-top: 5px;
  }

  .next-step-tail-line-active:after {
    width: 160px;
    content: '';
    display: inline-block;
    background: #47cca7;
    height: 3px;
  }

  .next-step-tail-line-inactive:after {
    width: 160px;
    content: '';
    display: inline-block;
    background: $lightGrey;
    height: 3px;
  }

  .next-step-background {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    align-self: center;
  }

  .next-step-finished-background {
    background-color: #47cca7;
  }

  .next-step-waiting-background {
    background-color: white;
    border: 3px solid;
    border-color: $lightGrey;
    box-sizing: border-box;
  }

  .next-step-finished-icon {
    object-fit: contain;
    width: 15px;
    height: 15px;
  }

  .next-step-waiting-icon {
    align-self: center;
    font-family: Muli;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    color: $deepGrey;
    margin-bottom: 0px;
  }

  .next-step-description-custom {
    top: 40px;
    font-family: MuliExtraBold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 0px;
    overflow: visible;
    position: absolute;
    width: 154px;
    left: 50%;
    transform: translateX(-50%);
  }

  .next-step-description-custom-inactive {
    color: $deepGrey;
  }

  .next-step-description-custom-actived {
    color: $inkBlack;
  }

  .next-step-icon-text-container-last {
    margin-right: 0px;
  }

  .next-step-icon-text-container-before {
    margin-right: 120px;
  }

  .next-step-icon-text-container {
    display: flex;
    flex-direction: column;
    width: 90px;
    position: relative;
  }
}
