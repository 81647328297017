@import '../../utils/Variables.scss';

.custom-page-container {
  border: none;
  padding: 0px 0px;
  margin: 0px 0px;
  border-radius: 0px;
  justify-content: center;
  display: flex;
}

.custom-page-item-common {
  width: 40px;
  height: 40px;
  margin-left: 6px;
  margin-right: 6px;
  padding: 0px 0px;
}
.custom-page-item-common:hover {
  cursor: pointer;
}

.custom-page-previous {
  object-fit: contain;
  transform: rotate(180deg);
}

.custom-page-next {
  object-fit: contain;
}

.custom-page-disabled {
  opacity: 0.3;
}

.custom-page-button-item-common,
.custom-page-button-item-common:focus {
  background: #ffffff;
  border: none;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #404040;
  box-shadow: none;
  border-radius: 20px;
}

.custom-page-button-item-common:hover,
.custom-page-button-item-common:not(:disabled):not(.disabled):active {
  background: #ffffff;
  background-color: #ffffff;
  color: $secondaryBtnColor;
  box-shadow: none;
  border: 1px solid #2b84ee;
  box-sizing: border-box;
  color: #4e8dd7;
  outline: none;
}

.custom-page-button-item-common:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.custom-page-button-item-common:disabled {
  background-color: $secondaryBtnBackgroundDisabled;
  opacity: 0.6;
  border-color: $secondaryBtnBorderColor;
  color: $secondaryBtnColorDisabled;
}

.custom-page-button-item-common:not(:disabled):not(.disabled):active:focus,
.custom-page-button-item-common:not(:disabled):not(.disabled).active:focus,
.show > .custom-page-button-item-common.dropdown-toggle:focus {
  box-shadow: none;
}

.custom-page-button-item-common:not(:disabled):not(.disabled):active,
.custom-page-button-item-common:not(:disabled):not(.disabled).active,
.show > .custom-page-button-item-common.dropdown-toggle {
  background: #ffffff;
  background-color: #ffffff;
  color: $secondaryBtnColor;
  box-shadow: none;
  border: 1px solid #2b84ee;
  box-sizing: border-box;
  color: #4e8dd7;
  outline: none;
}
