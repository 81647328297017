.create-category-prompt-input {
  display: flex;
  flex-direction: row;
}

.create-category-prompt-input-item {
  display: flex;
  flex-direction: column;
  margin-right: 40px;
}

.create-category-prompt-input-text {
  background: #ffffff;
  border: 1px solid #c2c2c2;
  box-sizing: border-box;
  border-radius: 4px;
  width: 200px;
  height: 40px;
}

.create-category-prompt-input-text:focus {
  border: 1px solid #c2c2c2;
  outline: none;
}

.grey-caution-of-priority {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #979797;
  margin-top: 8px;
}
