.onbarding-slide {
  .prompt-container {
    width: 100%;
    height: auto;
    padding: 0px 0px;
  }
  .close-image-index {
    z-index: 10;
  }
  .modal-content {
    width: 100vw;
    max-width: 1166px;
    padding-left: 57px;
    padding-right: 57px;
    border: none;
    background: transparent;
    background-color: transparent;
    align-items: center;
  }

  .vertical-height {
    height: 100%;
    margin-top: 42px;
    margin-bottom: 42px;
  }
  .horizontal-height {
    height: 100%;
    margin-top: 142px;
    margin-bottom: 142px;
  }

  .swiper-pagination-bullet {
    border: 1px solid #002148;
    box-sizing: border-box;
    background: white;
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 100%;
  }
  .swiper-pagination-bullet-active {
    opacity: 1;
    background: #002148;
  }
}
