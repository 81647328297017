.segment {
  .scroll-container-long-screen {
    width: 100%;
  }
  .create-step-section-item {
    min-width: 865px;
  }
  .filterable-dropdown-area {
    max-width: 400px;
  }
}
