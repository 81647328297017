.overview-row-title {
  font-family: Muli;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #002148;
  margin-left: 38px;
  margin-top: 10px;
}
.overview-campaigns-title {
  margin-top: 30px;
}

.overview-row {
  display: inline-flex;
  flex-wrap: wrap;
  padding: 30px 38px 10px 40px;
}
