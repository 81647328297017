@import '../../utils/Variables.scss';

.btn-link-text-label {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #8c8c8c;
  margin-bottom: 0px;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.btn-link-text-label-active {
  color: #404040;
}

.filterable-toogle-area-indicator {
  width: 16px;
  height: 16px;
  object-fit: contain;
  margin-left: 39px;
  align-self: center;
  transition: all 0.5s ease;
}

.filterable-toogle-area-label {
  width: 1px;
  height: 25px;
  object-fit: contain;
  align-self: center;
}

.filterable-dropdown-area {
  max-width: 500px;
  width: 100%;
}

.filterable-dropdown-area-prompt {
  max-width: 471px;
}

.filterable-toogle-area {
  height: 40px;
  padding: 5px 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid $lightGreyTwo;
  border-radius: 4px;
  color: $deepGrey;
}

.filterable-toogle-area:hover {
  cursor: pointer;
}

.filterable-dropdown-menu {
  background: #ffffff;
  border: 1px solid #c2c2c2;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(154, 161, 171, 0.3);
  border-radius: 0px 0px 4px 4px;
  padding: 10px 10px;
  width: 100%;
  z-index: 100;
}

.filterable-search-bar {
  height: 40px;
  // border: 1px solid #c2c2c2;
  // border-radius: 4px;
  display: flex;
}

.filterable-search-bar:hover {
  cursor: pointer;
}

.filterable-search-bar-icon {
  width: 16px;
  height: 16px;
  left: 20px;
  position: absolute;
  align-self: center;
  display: flex;
  margin-right: 10px;
}

.filterable-search-bar-input {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #404040;
  width: 100%;
  outline: none;
  box-shadow: none;
  border: none;
  height: 40px;
  padding-left: 36px;
  padding-right: 10px;
  border: 1px solid #c2c2c2;
  border-radius: 4px;
}

.filterable-search-bar-input:focus {
  background: #ffffff;
  border: 1px solid #2b84ee;
  box-shadow: 0px 0px 8px rgba(43, 132, 238, 0.5);
  border-radius: 4px;
}

.filterable-search-bar-input::placeholder {
  color: #8c8c8c;
}

.filterable-dropdown-menu-select-area {
  max-height: 210px;
  overflow-y: auto;
  margin-bottom: 0px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  overflow-x: hidden;
}

.filterable-item {
  height: 40px;
  width: 100%;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 40px;
  color: #404040;
  padding: 0px 0px;
  overflow-x: hidden;
  text-overflow: ellipsis;

  a:active {
    color: #2b84ee;
    background-color: #ffffff;
  }
}

.filterable-item:active,
.filterable-item:focus,
.filterable-item:hover {
  color: #2b84ee;
  background-color: #ffffff;
  border: none;
  box-shadow: none;
  outline: none;
}

.rtoate180 {
  transform: rotate(180deg);
}

.filterable-dropdown-delete-selected {
  position: absolute;
  right: 38px;
  width: 27px;
  height: 27px;
  object-fit: contain;
  align-self: center;
}

.filter-buttons-bar {
  margin-top: 10px;
  margin-left: 6px;

  .filter-btn {
    width: 85px;
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    font-weight: bold;
    color: #404040;
    background-color: #ffffff;
    border: 0px;

    &.filter-btn-selected,
    &:focus {
      color: #ffffff;
      background: #4e8dd7;
      border-radius: 6px;
      outline: 0px;
    }
  }
}
